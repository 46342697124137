/* SEAT FONTS */

@font-face {
  font-family: 'Seat Bcn';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/seat-webfont/SeatBcn-Light.woff);
}

@font-face {
  font-family: 'Seat Bcn';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/seat-webfont/SeatBcn-Regular.woff);
}

@font-face {
  font-family: 'Seat Bcn';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/seat-webfont/SeatBcn-Medium.woff);
}

@font-face {
  font-family: 'Seat Bcn';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/seat-webfont/SeatBcn-Bold.woff);
}

/* CUPRA FONTS */

@font-face {
  font-family: 'Cupra';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/cupra-webfont/Cupra-Light.woff);
}

@font-face {
  font-family: 'Cupra';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/cupra-webfont/Cupra-Regular.woff);
}

@font-face {
  font-family: 'Cupra';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/cupra-webfont/Cupra-Medium.woff);
}

@font-face {
  font-family: 'Cupra';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url(../fonts/cupra-webfont/Cupra-Bold.woff);
}
